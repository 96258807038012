import React, { Fragment, useEffect, useState,useRef } from 'react'
import './counsellorScreen.css'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import Loader from '../../components/loader/Loader'
import {ConsellorFilter, counsellorData, CounsellorDetails, GetAllCouncelors} from '../../store/interface'
import { FaFilter } from "react-icons/fa";
import { ImEqualizer2 } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import DoubleSlider from '../../components/slider/DoubleSlider'
import { GetCouncellors } from '../../api/councellor'
import counsellorImage from "../../assets/images/counselarNew.png";
import Pagination from '../../components/pagination/Pagination'
import { useNavigate } from 'react-router-dom'


const initialFilter:ConsellorFilter ={
  pageNumber:1,
  pageSize:2,
  name:"",
  diagnosis:[],
  experienceFrom:0,
  experienceTo:0,
  sort:1,
}

const initcounsellorData:counsellorData={
  counsellors:[],
  totalPages:1
}
const initialCouncellors: GetAllCouncelors = {
  statusCode: 0,
  data: initcounsellorData,
  message: "",
  isSuccessful: false,
};

const Counsellors: React.FC = () => {
// #region state variables
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [filter, setFilter] = useState<ConsellorFilter>(initialFilter);
  const [cachedFilter, setCachedFilter] = useState<ConsellorFilter>(initialFilter);
  const [councellorsdata, setCouncellorsdata] =
    useState<GetAllCouncelors>(initialCouncellors);
// #endregion
//#region fetch consellors
const fetchCouncellorsdata = async (filter:ConsellorFilter) => {
  try {
    setLoader(true);
    const response = await GetCouncellors(filter);
    setLoader(false)
    setCouncellorsdata(response.data);
    console.log(response.data);
  } catch (error) {
    setLoader(false)
    console.error("Error fetching leaderboard data:", error);
  }
};
//#endregion
useEffect(() => {
  fetchCouncellorsdata(filter);
}, []);


// #region pagination
const handlePageChange = (selectedItem: { selected: number }) => {
  setFilter(prevState => {
    const newState = {
      ...prevState,
      pageNumber: selectedItem.selected + 1,
    };
    fetchCouncellorsdata(newState);
    return newState;
  });
};
// #endregion

// #region handling filter types
const handleClearFilter = ()=>{
  setFilter(prevState => {
    fetchCouncellorsdata(initialFilter);
    return initialFilter;
  });
  buttonRef.current?.click();
}

const handleApplyFilter = () =>{
  setFilter(prevState => {
    const newState = {
      ...prevState,
      name:cachedFilter.name,
      diagnosis:cachedFilter.diagnosis,
      experienceFrom:cachedFilter.experienceFrom,
      experienceTo:cachedFilter.experienceTo,
      sort:cachedFilter.sort,
      pageNumber: 1,
    };
    fetchCouncellorsdata(newState);
    return newState;
  });
  buttonRef.current?.click();
}

const handleDiagnosis=(e:React.ChangeEvent<HTMLInputElement>,diagnosis:string)=>{
  const _val=e.target.checked;
  let arr=cachedFilter.diagnosis;
  if(_val){
    let _dignosisIndex=arr.indexOf(diagnosis);
    if (_dignosisIndex > -1) {
      arr.splice(_dignosisIndex, 1);
    }
  }
  else{
    let _dignosisIndex=arr.indexOf(diagnosis);
    if (!(_dignosisIndex > -1)) {
     arr=[...arr,diagnosis];
    }
  }
  setCachedFilter(prevState => {
    const newState = {
      ...prevState,
      diagnosis:arr
    };
    return newState;
  });
}

const handleSliderChange = (newValues: [number, number]) => {
  let _sliderValues=newValues;
  if(_sliderValues[0]===0 && _sliderValues[1]===100){
    _sliderValues[1]=0;
  }

  setCachedFilter(prevState => {
    const newState = {
      ...prevState,
      experienceFrom:_sliderValues[0],
      experienceTo:_sliderValues[1]
    };
    return newState;
  });
};

const handleCounsellorInfo = (counsellor:CounsellorDetails)=> {
  navigate("/counsellorInformation",{ state: { counsellor } });
}
// #endregion

  
  return (
    <div className="layout-div">
      <Fragment>
        <Header/>
        {loader && <Loader/>}
        {/* HEADER SECTION */}
        <Fragment>
          <div className='counsellor-filter-button-container'>
            <div className='counsellor-header-div'><h2>Counsellors</h2></div>
            <div>
              <button style={{backgroundColor:"#00387d",color:"#fff",marginBottom:"22px"}} data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom" className=" btn btn-info text-center rounded-pill ">
                <FaFilter color='white' size={20} /><strong className='px-2'>Filter</strong>
                <span className='px-2'></span>
                <ImEqualizer2 color='white' size={20} /><strong>Sort By</strong>
              </button>
            </div>
          </div>
        </Fragment>
        {/* Body section */}
        <div className="container div-div">
          <div className="row col-12">
            {councellorsdata  && councellorsdata.data && councellorsdata.data.counsellors.map((counsellor) => (
              <Fragment>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 card-default-padding" key={counsellor.id}>
                  <div className="card glass text-center mb-3">
                    <div className="card-img-container">
                      <img
                        src={counsellor.imagePath?counsellor.imagePath:counsellorImage}
                        alt={counsellor.name}
                        className='card-img-top'
                      />
                    </div>
                    <div className="card-body">
                      <div className="counsellor-title-div">
                        <h5 className="card-title" title={counsellor.name}>
                          {counsellor.name}
                        </h5>
                      </div>
                      <div className="counsellor-title-div">
                        <h5 className="card-title" title={counsellor.experience?counsellor.experience+"":"0"}>
                          {counsellor.experience + " Experience"}
                        </h5>
                      </div>
                      
                      <div className="row px-3 counsellor-button-div">
                      <button onClick={()=>handleCounsellorInfo(counsellor)} className="rounded-pill ">Know More</button>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          {councellorsdata  && councellorsdata.data && <Pagination currentPageNumber={filter.pageNumber-1} totalPageCount={councellorsdata.data.totalPages??1} handlePageChange={handlePageChange}/>}
          </div>
        </div>
        {/* filter and sorting section */}
        <Fragment>
          <div className="offcanvas offcanvas-bottom" data-bs-scroll="true" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasBottomLabel">Filter & sorting</h5>
              <button ref={buttonRef} type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body small" style={{padding:"10px 10px 40px"}}>
              <div className="col-12">
                <div className='row col-12'>
                  {/* Filter */}
                  <div className="card-div col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div  className='card' >
                      <div className="card-header">
                        <h4>Filter</h4>
                      </div>
                      <div className="card-body">
                        <div className="accordion" id="accordionExample">
                          <div>
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button rounded-pill collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Search By Name
                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <input type="text" onChange={(e)=>setCachedFilter(prevState=>({...prevState,name:e.target.value}))} className='rounded-pill px-4' placeholder='Enter Consellor Name' />
                              </div>
                            </div>
                          </div>
                          <div>
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button rounded-pill collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Diagnosis Types
                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                              <div className='counsellor-filter-checkbox-container'>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis1")}/> diagnosis1</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis2")}/> diagnosis2</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis3")}/> diagnosis3</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis4")}/> diagnosis4</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis5")}/> diagnosis5</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis6")}/> diagnosis6</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis7")}/> diagnosis7</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis8")}/> diagnosis8</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis9")}/> diagnosis9</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis10")}/> diagnosis10</div>
                                <div className='check-box-div'><input type="checkbox" defaultChecked={true} onChange={(e)=>handleDiagnosis(e,"diagnosis11")}/> diagnosis11</div>
                              </div>
                                
                              </div>
                            </div>
                          </div>
                          <div>
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button rounded-pill collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Year Of Experience
                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                              <div>
                              <DoubleSlider from={cachedFilter.experienceFrom} to={ cachedFilter.experienceFrom===0 && cachedFilter.experienceTo===0?100:cachedFilter.experienceTo} onChange={handleSliderChange}/>
                              </div>
                              </div>
                            </div>
                          </div>
                        </div>  
                      </div>
                    </div>
                  </div>
                  {/* Sorting */}
                  <div className="card-div col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className='card '>
                      <div className="card-header">
                        <h4>Sort by</h4>
                      </div>
                      <div className="card-body">
                        <button className='radio-button rounded-pill px-4' onClick={()=>setCachedFilter(prevState=>({...prevState,sort:1}))}>From A to Z {cachedFilter.sort===1 && <FaCheck color='green'/>}</button>
                        <button className='radio-button rounded-pill px-4' onClick={()=>setCachedFilter(prevState=>({...prevState,sort:2}))}>From Z to A {cachedFilter.sort===2 && <FaCheck color='green'/>}</button>
                        <button className='radio-button rounded-pill px-4' onClick={()=>setCachedFilter(prevState=>({...prevState,sort:3}))}>Experience low to high {cachedFilter.sort===3 && <FaCheck color='green'/>}</button>
                        <button className='radio-button rounded-pill px-4' onClick={()=>setCachedFilter(prevState=>({...prevState,sort:4}))}>Experience high to low {cachedFilter.sort===4 && <FaCheck color='green'/>}</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-row-reverse">
                  <div>
                    <button onClick={handleApplyFilter} className='fit-content btn btn-primary rounded-pill mx-1'>Apply filters</button>
                    <button onClick={handleClearFilter} className='fit-content btn btn-secondary rounded-pill mx-1'>Clear filters</button>
                  </div>
                    
                </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
        {/* footer section */}
        <div style={{position:"relative",zIndex:"2"}}>
          <Footer/>
        </div>
      </Fragment>
    </div>
  )
}

export default Counsellors