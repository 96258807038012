import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { RegistrationRequestModel, SignupModel } from '../interface';



export const initialSignUp :SignupModel={
  email:'',
  phoneNumber:'',
  proceedToOTP:false,
  emailOTP:0,
  phoneOTP:0,
  isEmailVerified:false,
  isPhoneVerified:false,
  loading:false
} 

export const initialRegistrationRequest:RegistrationRequestModel={
  email:'',
  phoneNumber:'',
  username:'',
  password:'',
  confirmPassword:'',
  gender:'',
  dob:'',
  instituteCode:'',
  termsAccepted:false,
  loading:false
}

const signupSlice = createSlice({
  name: 'signup',
  initialState:initialSignUp,
  reducers: {
    getOTP: (state, action: PayloadAction<{ reqData: SignupModel}>) => {
      state.proceedToOTP=false;
      state.loading=true;
    },
    onGetOtpSuccess: (state) => {
      state.proceedToOTP=true;
      state.loading=false;
    },
    onGetOtpFailure: (state) => {
      state.proceedToOTP=false;
      state.loading=false;
    },
    verifyEmail:(state,action:PayloadAction<{reqData:SignupModel}>)=>{
      state.isEmailVerified=false;
      state.loading=true;
    },
    onVerifyEmailSuccess:(state)=>{
      state.isEmailVerified=true;
      state.loading=false;

    },
    onVerifyEmailFailure:(state)=>{
      state.isEmailVerified=false;
      state.loading=false;
    },
    verifyPhone:(state,action:PayloadAction<{reqData:SignupModel}>)=>{
      state.isPhoneVerified=false;
      state.loading=true;
    },
    onVerifyPhoneSuccess:(state)=>{
      state.isPhoneVerified=true;
      state.loading=false;

    },
    onVerifyPhoneFailure:(state)=>{
      state.isPhoneVerified=false;
      state.loading=false;
    },
    resendEmailOTP:(state,action:PayloadAction<{reqData:SignupModel}>)=>{},
    resendPhoneOTP:(state,action:PayloadAction<{reqData:SignupModel}>)=>{},
    setSignUp(state, action: PayloadAction<SignupModel>) {
      return action.payload;
    },
    updateSignup(state, action: PayloadAction<Partial<SignupModel>>) {
      return { ...state, ...action.payload };
    },
  },
});


const registrationRequest = createSlice({
  name: 'registationRequest',
  initialState:initialRegistrationRequest,
  reducers: {
    registerRequest: (state, action: PayloadAction<{ reqData: RegistrationRequestModel,navigate: NavigateFunction}>) => {
      state.loading=true;
    },
    onRegisterRequestSuccess: (state) => {
      state.loading=false;
    },
    onRegisterRequestFailure: (state) => {
      state.loading=false;
    },
    setRegistrationRequest(state, action: PayloadAction<RegistrationRequestModel>) {
      return action.payload;
    },
    updateRegistrationRequest(state, action: PayloadAction<Partial<RegistrationRequestModel>>) {
      return { ...state, ...action.payload };
    },
  },
});


export const { getOTP,onGetOtpSuccess,onGetOtpFailure,verifyEmail,onVerifyEmailSuccess,onVerifyEmailFailure,verifyPhone,onVerifyPhoneSuccess,onVerifyPhoneFailure,resendEmailOTP,resendPhoneOTP,setSignUp, updateSignup } = signupSlice.actions;
export const { registerRequest, onRegisterRequestSuccess,onRegisterRequestFailure,setRegistrationRequest,updateRegistrationRequest } = registrationRequest.actions;

export default {
  signupReducer: signupSlice.reducer,
  registrationRequestReducer: registrationRequest.reducer,
};