import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { RootState } from '../../store';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Swal from 'sweetalert2';
import { deleteAccount } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ApiError } from '../../store/interface';
import Loader from '../../components/loader/Loader';

const DeleteAccount: React.FC = () => {
    const user = useSelector((state:RootState)=>state.auth.user);
    const [loader,setLoader]=useState<boolean>(false);
    const navigate=useNavigate();
    useEffect(()=>{
        if(!user){
            Swal.fire({
                title: "Warning",
                text: "You have not logged in, Want to login",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#4169e1",
                cancelButtonColor: "#0d6efd",
                confirmButtonText: "Yes, I'm!"
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/login');
                }
            });
        }

    },[]);
    const callDeleteAccount = async () => {
        try {
          setLoader(true);
          const response = await deleteAccount();
          debugger;
          setLoader(false);
    
          Swal.fire({
            title: "Deleted!",
            text: "Your account has been deleted.",
            icon: "success",
            confirmButtonColor: "#0d6efd",
            confirmButtonText:"OK"
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/login");
    
            }
          });
    
        } catch (error) {
          debugger;
          setLoader(false);
          console.error("Error deleting user account:", error);
          let _errorMsg: string = "";
          let _errorHtml:string="";
          if (error instanceof AxiosError) {
            const _i:ApiError=error.response?.data;
            _errorMsg = _i.message;
            let a ="<ul>";
            _i.errors.map((item,index)=>{
              a=a+"<li>"+Object.values(item)+"</li>"
            });
            a=a+"</ul>";
            _errorHtml= a.length>10?a:_errorHtml;
    
          } else {
            _errorMsg = "Something went wrong! Try after sometime";
          }
    
          Swal.fire({
            title: "Error",
            text: _errorMsg,
            html:_errorHtml,
            icon: "error",
            position: "top",
          });
        }
      };
      const handlelGoBackToLogin = ()=>{
        navigate('/login');
      }
      const handleDeleteAccount = () =>{
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#0d6efd",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                callDeleteAccount();
            }
        });
      }
    return (
        <Fragment>
            <Header/>
            {loader && <Loader/>}
            <div style={{width:"100%",height:"100vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
                <div style={{width:"fit-content"}}>
                <button className='btn btn-primary' onClick={handleDeleteAccount} >Delete account</button>
                <button className='btn btn-primary' onClick={handlelGoBackToLogin} >Go back to Login</button>
                </div>
            </div>
            <Footer/>
        </Fragment>
    )
}

export default DeleteAccount