import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  ChartData,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale
);

const LineChart: React.FC = () => {
  const data: ChartData<"line"> = {
    labels: [
      "1st(Sem) ",
      "2nd(Sem)",
      "3rd(Sem)",
      "4th(Sem)",
      "4th(Sem)",
      "5th(Sem)",
    ],
    datasets: [
      {
        label: "Math",
        data: [85, 88, 75, 90, 95, 93],
        fill: false,
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.1,
      },
      {
        label: "Science",
        data: [80, 82, 78, 85, 87, 84],
        fill: false,
        borderColor: "rgba(54, 162, 235, 1)",
        tension: 0.1,
      },
      {
        label: "English",
        data: [70, 72, 68, 75, 78, 77],
        fill: false,
        borderColor: "rgba(255, 206, 86, 1)",
        tension: 0.1,
      },
      {
        label: "History",
        data: [60, 65, 63, 70, 72, 71],
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false, // Hides the vertical grid lines
        },
      },
      y: {
        grid: {
          display: false, // Hides the horizontal grid lines
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default LineChart;
