import axios from './_dotnetConfiguration';
import { ApiResponse, ConsellorFilter, GetAllCouncelors, SlotsResponse } from '../store/interface';

// export const getInstitutionalboard = async() =>{
//     debugger;
//     return await axios.get<InstitutionalResponse>('/Leaderboard/GetInstitutionalRankings');
//   }

export const  GetCouncellors = async(filter:ConsellorFilter) =>{
    return await axios.post<GetAllCouncelors>(`/Councellor/GetAllCouncellors`,filter);
}

export const GetAvailableSlotsByCounsellor = async(counsellorId:number) =>{
    debugger;
    return await axios.post<SlotsResponse>(`/Councellor/GetAvailableSlotsByCounsellor?counsellorId=${counsellorId}`);
}

export const BookAFreeSlot =async(bookingId:string,paymentDone:boolean)=>{
    debugger;
    return await axios.post<ApiResponse>(`Councellor/BookASlot?bookingId=${bookingId}&paymentDone=${paymentDone}`);
}
// export const  GetCouncellors = async() =>{
//     return await axios.get<counsellorDetails>('/Councellor/GetAllCouncellors');
// }