import React from "react";
import { useState, ChangeEvent } from "react";
import "./GetinTouchWithUs.css";
const GetinTouchWithUs: React.FC = () => {
  const [email, setEmail] = useState("");

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="getInTouch  rounded  d-flex align-items-center justify-content-around mx-auto">
          <h2 className="getInTouchHeader text-white fw-bold  ">
            get in touch with us
          </h2>
          <div className="getInTouchHeaderBody d-flex align-items-center bg-secondary rounded-pill  ">
            <input
              className="getInTouchEmail  flex-grow-1 border-0 rounded-pill ms-1 me-3 p-2"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <div className="letsTalkBody d-flex align-items-center justify-content-center bg-white rounded-pill me-1 me-sm-3  px-3 py-2">
              <h2 className="letsTalk text-primary fw-bold  ">Let’s talk</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetinTouchWithUs;
