// import React from "react";
// import "./HomeBanner.css";
// const HomeBanner: React.FC = () => {
//   return (
//     <>
//       <div className="container-fluid">
//         <div className="banner  row bg-primary justify-content-center ">
//           <div className="left-banner col-12 col-md-8 text-center p-4 mb-3 mb-sm-1 mb-md-0">
//             <span className="DownloadText text-white  fw-bold fz-xs-3 fz-sm-3 fz-md-4 fz-lg-4 fz-xl-4">
//               Download App To Unlock More Benefits
//             </span>
//           </div>
//           <div className="right-banner col-4 col-md-3 text-center text-md-start mb-3">
//             <button className="DownloadButton  btn btn-warning p-3 fs-6 fw-bold rounded">
//               <span className="buttontext fw-bold fz-xs-3 fz-sm-3 fz-md-4 fz-lg-4 fz-xl-4">
//                 Download App
//               </span>
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default HomeBanner;

import React from "react";
import "./homeBanner.css";

const HomeBanner: React.FC = () => {
  return (
    <div className="container-fluid bg-primary">
      <div className="banner d-flex row justify-content-center">
        <div className="left-banner col-12 col-md-8 text-center">
          <span className="DownloadText">
            Download App To Unlock More Benefits
          </span>
        </div>
        <div className="right-banner col-8 col-md-4 text-center text-md-start">
          <button className="DownloadButton bg-warning ">
            <span className="buttontext ">Download App</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
