import React, { Fragment, useEffect, useRef, useState } from 'react'
import './counsellorInformation.css'
import Header from '../../../components/header/Header'
import Loader from '../../../components/loader/Loader'
import Footer from '../../../components/footer/Footer'
import counsellorImage from "../../../assets/images/counselarNew.png";
import { SlotsResponse, CounsellorDetails, DateSlot, TimeAndDuration, WeeksInfo, CheckoutResponse, CheckoutRequest, ApiError } from '../../../store/interface'
import Swal from 'sweetalert2'
import { FaForward } from "react-icons/fa6";
import { BiSolidRightArrow } from "react-icons/bi";
import { BiSolidLeftArrow } from "react-icons/bi";
import { useLocation } from 'react-router-dom';
import { BookAFreeSlot, GetAvailableSlotsByCounsellor } from '../../../api/councellor'
import { Checkout, ValidatePayment } from '../../../api/payment'
import { AxiosError } from "axios";

const initialCounsellorData:CounsellorDetails={
  id:0,
  counsellorId:"ABC",
  name: "SUJITH",
  organisationId: 0,
  isPaid: true,
  feePerSession: 100,
  expertise: "Counselling",
  bio: "More you get, more you lose",
  experience: 5,
  imagePath: null
}

const initialSlots:SlotsResponse={
  statusCode:null,
  data:null,
  message:null,
  isSuccessful:null
}
interface counsellorProps{
  councellorData:CounsellorDetails|null
}

const CounsellorInformation: React.FC<counsellorProps> = () => {
  //#region use state
  const buttonRef = useRef<HTMLButtonElement>(null);
  const closeBtnRef = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const [loader, setLoader] = useState<boolean>(false);
  const [counsellor, setCounsellor] = useState<CounsellorDetails>(location.state.counsellor?location.state.counsellor:initialCounsellorData);
  const [slots, setSlots] = useState<SlotsResponse>(initialSlots);
  const [month, setMonth] = useState<number>(new Date().getMonth());
  const [weeksInfo, setWeeksInfo] = useState<WeeksInfo[]|null>();
  const [monthArr, setMonthArr] = useState<number[]>([]);
  const [totalSlots, setTotalSlots] = useState<{id:number,date:string,startsAt:string,duration:string;slotBookingId:string}[]>();
  const [weekSlots, setWeekSlots] = useState<{id:number,date:string,startsAt:string,duration:string;slotBookingId:string}[]>();
  const [monthNames,setMonthNames] =useState<string[]>(["Jan",  "Feb",  "Mar",  "Apr",  "May",  "Jun",  "Jul",  "Aug",  "Sep",  "Oct",  "Nov",  "Dec"] );
  const [checkout, setCheckout] = useState<CheckoutResponse>();
  //#endregion

  //#region formatting date and time
  const formatDate = (date:Date)=> {
    let _day =date.getDate();
    let _month = monthNames[date.getMonth()];
    let _year = date.getFullYear();
    return _day+"-"+_month+"-"+_year;
  }
  const formatTime =(time24:string) =>{
    const [hours, minutes, seconds] = time24.split(':');
    let formattedHours = parseInt(hours, 10);
    const amPm = formattedHours >= 12 ? 'PM' : 'AM';
    
    formattedHours %= 12;
    formattedHours = formattedHours || 12;
    
    return `${formattedHours}:${minutes}:${seconds.split('.')[0]} ${amPm}`;
  }
  //#endregion
  
  //#region compare funcation for filter
  const compareWithCurrentDate=(data:DateSlot)=>{
    let a =new Date(data.date);
    let b = new Date();
    return a>=b;
  }
  
  const compareWithMonth=(data:DateSlot)=>{
    let a =new Date(data.date).getMonth();
    return a===month;
  }
  //#endregion

  //#region helper functions
  const getWeeksInMonth=(year:number, month:number)=> {
    
    const weeks:any = [],
      firstDate = new Date(year, month, 1),
      lastDate = new Date(year, month + 1, 0),
      numDays = lastDate.getDate(),
      weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
  
    let dayOfWeekCounter = firstDate.getDay();

    // let _currentDate = new Date();
    // _currentDate.setDate(_currentDate.getDate() - 1);
    
    for (let date = 1; date <= numDays; date++) {
      //if(_currentDate < new Date(year,month,date)){
        if (dayOfWeekCounter === 0 || weeks.length === 0) {
          weeks.push([]);
        }
        let day = new Date(year,month,date).getDay()
        let weekDay= weekdays[day];
        let _push= {date:date,day:weekDay};
        if( weeks[weeks.length - 1]){
          weeks[weeks.length - 1].push(_push);
  
        }
        dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
      //}
    }
  
    const _setWeeks = weeks
      .filter((w:any[]) => !!w.length)
      .map((w:any[]) => ({
        weeks: w,
      }));

     setWeeksInfo(_setWeeks);


  };

  const slotsForWeek =(weekIndex:number)=>{
    let slotCount:number=0;
    if(slots.data && slots.data.length>0 && slots.data.filter(compareWithMonth).length>0){
      const _year=new Date().getMonth()<=month?new Date().getFullYear():new Date().getFullYear()+1;
      const _weekArr =  weeksInfo && weeksInfo.length>0?weeksInfo[weekIndex].weeks:null;
      
      if(_weekArr){
        let startDate = new Date(_year,month,_weekArr[0].date);
        let endDate = new Date(_year,month,_weekArr[_weekArr.length-1].date);
        if(endDate>=new Date()){
          endDate.setDate(endDate.getDate() + 1);
          const _slotInfo:{date:string,slot:string}[]=[];
          slots?.data.map(x=>{ 
            x.timeAndDurations.map(y=>{
              _slotInfo.push({date:x.date,slot:y.timeSlot})
            })
          });

          slotCount = _slotInfo.filter(obj => {
              const objDate = new Date(obj.date);
              return objDate >= startDate && objDate < endDate;
          }).length;
        }
      }
    }
    return slotCount;
  }
  //#endregion

  //#region fetch counsellor slots
  const fetchCounsellorSlots = async () => {
    try {
      setLoader(true);
      const response = await GetAvailableSlotsByCounsellor(counsellor.id);
      setLoader(false);
      if(response.data.data && response.data.data.length>0){
        const futureSlots=response.data.data.filter(compareWithCurrentDate);
        setSlots(prevState=>({...prevState,data:futureSlots}));
        
        let i=0;
        let _totalSlots:{id:number,date:string,startsAt:string,duration:string;slotBookingId:string}[]=[];
        futureSlots?.map(x=>{ 
          x.timeAndDurations.map(y=>{
            i=i+1;
            _totalSlots.push({id:i,date:x.date,startsAt:y.timeSlot,duration:y.duration+"",slotBookingId:y.bookingId})
          })
        });
        setTotalSlots(_totalSlots);
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching counsellor slots data:", error);
    }
  };
  //#endregion
  //#region book free slots
  const bookfreeSlots = async (bookingId:string)=>{
   try{
    setLoader(true);
    const response = await BookAFreeSlot(bookingId,true);
    setLoader(false);
    Swal.fire({
      title: "Success",
      text: "Slot booked successfully",
      icon: "success",
      position: "top",
    });
   }catch (error) {
    setLoader(false);
    Swal.fire({
      title: "Error",
      text: "Error in booking slot",
      icon: "error",
      position: "top",
    });
    console.error("Error fetching counsellor slots data:", error);
  }
  }

  //#endregion
  //#region use effect to call once
  useEffect(() => {
    
    if(location.state.counsellor){
      setCounsellor(prevState => {
        const newState = {
          ...prevState,
         ...location.state.counsellor
        };
        return newState;
      });
    }
    // const sd={
    //   "statusCode": 200,
    //   "data": [
    //     {
    //       "date": "2024-10-09",
    //       "timeAndDurations": [
    //         {
    //           "timeSlot": "16:58:21.4390000",
    //           "duration": 30
    //         }
    //       ]
    //     },
    //     {
    //       "date": "2024-10-20",
    //       "timeAndDurations": [
    //         {
    //           "timeSlot": "16:58:21.4390000",
    //           "duration": 30
    //         }
    //       ]
    //     },
    //     {
    //       "date": "2024-11-17",
    //       "timeAndDurations": [
    //         {
    //           "timeSlot": "14:12:28.6820000",
    //           "duration": 30
    //         }
    //       ]
    //     },
    //     {
    //       "date": "2024-11-23",
    //       "timeAndDurations": [
    //         {
    //           "timeSlot": "14:12:28.6820000",
    //           "duration": 30
    //         }
    //       ]
    //     },
    //     {
    //       "date": "2024-11-24",
    //       "timeAndDurations": [
    //         {
    //           "timeSlot": "14:12:28.6820000",
    //           "duration": 30
    //         }
    //       ]
    //     },
    //     {
    //       "date": "2024-12-01",
    //       "timeAndDurations": [
    //         {
    //           "timeSlot": "14:12:28.6820000",
    //           "duration": 30
    //         }
    //       ]
    //     }
    //   ],
    //   "message": "Results fetched successfully",
    //   "isSuccessful": true
    // };
    
    fetchCounsellorSlots();
    // console.log(counsellor);
    // setSlots(prevstate=>({
    //   ...prevstate,
    //   ...sd
    // }));
    //console.log(slots);

    

    //console.log(slots);
    const _currentYear:number=new Date().getFullYear();
    const _currentMonth:number=new Date().getMonth();
    getWeeksInMonth(_currentYear,_currentMonth);


    let arr=[];
    let currentMonth=new Date().getMonth();
    for(let i=0;i<12;i++){
        arr.push(currentMonth);
        let nextMonth=currentMonth+1;
        if(currentMonth==11){
            nextMonth=0;
        }
        currentMonth=nextMonth;   

    };
    setMonthArr(arr);

  }, []);
  //#endregion

  //#region  handling events
  const handleMonth=(e:React.ChangeEvent<HTMLSelectElement>)=>{
    const _month =parseInt(e.target.value);
    const _year=new Date().getMonth()<=_month?new Date().getFullYear():new Date().getFullYear()+1;
    setMonth(_month);
    setWeekSlots([]);
    getWeeksInMonth(_year,_month);

  }  
  const handleViewWeekSlots=(weekIndex:number)=>{
    if(slots.data && slots.data.length>0 && slots.data.filter(compareWithMonth).length>0){
      const _year=new Date().getMonth()<=month?new Date().getFullYear():new Date().getFullYear()+1;
      const _weekArr =  weeksInfo && weeksInfo.length>0?weeksInfo[weekIndex].weeks:null;
      
      if(_weekArr){
        let startDate = new Date(_year,month,_weekArr[0].date);
        let endDate = new Date(_year,month,_weekArr[_weekArr.length-1].date);
        if(endDate>=new Date()){
          endDate.setDate(endDate.getDate() + 1);
          const _slotInfo  = totalSlots?.filter(obj => {
              const objDate = new Date(obj.date);
              return objDate >= startDate && objDate < endDate;
          });
          setWeekSlots(_slotInfo);
        
        }
      }
    }
  }
  const handleLeftRightMonth = (direction:string)=>{
    let currentMontIndex=monthArr?.indexOf(month);
    let lastIndex = monthArr.length-1>=0?monthArr.length-1:0;
    if(direction=="Left"){
      if(month!=monthArr[0] ){
        let _month=monthArr[currentMontIndex-1];
        const _year=new Date().getMonth()<=_month?new Date().getFullYear():new Date().getFullYear()+1;
        setMonth(_month);
        setWeekSlots([]);
        getWeeksInMonth(_year,_month);
      }
    }
    else{
      if(month!=monthArr[lastIndex] ){
        let _month=monthArr[currentMontIndex+1];
        const _year=new Date().getMonth()<=_month?new Date().getFullYear():new Date().getFullYear()+1;
        setMonth(_month);
        setWeekSlots([]);
        getWeeksInMonth(_year,_month);
      }
    }
  };
  const handleBookSlot = (slotId:number) => {
    if(counsellor.feePerSession &&counsellor.feePerSession>0){
      buttonRef.current?.click();

    }
    else{
      Swal.fire({
        title: "Success",
        text: "Slot booked successfully",
        icon: "success",
        position: "top",
      });
    }
    
  };
  const handlePaymentDone =()=>{
    Swal.fire({
      title: "Success",
      text: "Recived your payment and slot booked successfully",
      icon: "success",
      confirmButtonColor: "#00387d",
      confirmButtonText:"OK"
    }).then((result) => {
      if (result.isConfirmed) {
        closeBtnRef.current?.click();
      }
    });
  }
  //#endregion

  //#region handling checkout
  const checkouCall = async (req:CheckoutRequest) => {
    try {
      setLoader(true);
      const response = await Checkout(req);
      setLoader(false);
      debugger;
      if(response.data.data){
        setCheckout(()=>({...response.data.data}));
        return response.data.data;
      }
      else{
        Swal.fire({
          text: response.data.message??"",
          icon: "info",
          position: "top",
        });
      }
    } catch (error) {
      setLoader(false);
      
      console.error("Error fetching checkout data:", error);
      let _errorMsg: string = "";
      let _errorHtml:string="";
      if (error instanceof AxiosError) {
        const _i:ApiError=error.response?.data;
        _errorMsg = _i.message;
        let a ="<ul>";
        _i.errors.map((item,index)=>{
          a=a+"<li>"+Object.values(item)+"</li>"
        });
        a=a+"</ul>";
        _errorHtml= a.length>10?a:_errorHtml;

      } else {
        _errorMsg = "Something went wrong! Try after sometime";
      }
      
      Swal.fire({
        title: "Error",
        text: _errorMsg,
        html:_errorHtml,
        icon: "error",
        position: "top",
      });
    }
    return null;
  };
  const handleCheckout = async (cost:number|null,bookingId:string) =>{
    debugger;
    if(cost && cost>0){
      let req:CheckoutRequest ={
        OrderType :"slot",
        OrderTypeId :bookingId,
        OrderQuantity :1,
      }
      const _res:CheckoutResponse|null = await checkouCall(req);
      
      if(_res){
        let options  = {
          "key": "rzp_test_V3jY35SlgjTLb2", // Enter the Key ID generated from the Dashboard
          "amount":_res?.amount??0, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "INR",
          "name": "YouEvolve",
          "description": "Payment for counsellor slot booking",
          "image": "https://example.com/your_logo",
          "order_id": _res?.id??"", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "handler": async function (response:any) {
            debugger;
              try {
                  const validationResult = await ValidatePayment({
                    "razorpay_payment_id":response.razorpay_payment_id, 
                    "razorpay_order_id":response.razorpay_order_id, 
                    "razorpay_signature":response.razorpay_signature
                  });
                  Swal.fire({
                    title:"Success",
                    text: "Slot booked successfully",
                    icon: "success",
                    position: "top",
                  });
      
      
                  console.log('Payment validation result:', validationResult);
                  // Handle success response (e.g., show a success message)
      
              } catch (error) {
                Swal.fire({
                  title: "Error in booking slot",
                  icon: "error",
                  position: "top",
                });
                  console.error('Payment validation failed:', error);
                  // Handle error response (e.g., show an error message)
              }
          },
          "prefill": {
            "name": "Sujith Kumar Y",
            "email": "sujith.kumar@example.com",
            "contact": "9000090000"
          },
          "notes": {
              "address": "Razorpay Corporate Office"
          },
          "theme": {
              "color": "#00387d"
          }
        };
        var rzp1 = new (window as any).Razorpay(options);
        rzp1.open();

      }
      

    }
    else{
      bookfreeSlots(bookingId);
    }
   
  }
  //#endregion
  
  return (
    <div className="layout-div">
      <Fragment>
        <Header/>
        {loader && <Loader/>}
        <div className='body-div div-div'>
          <div className="my-3 px-5 col-12">
            <div className='counsellor-name-div'><h2>Counsellor Information</h2></div>
            
          </div>
          {/* basic information */}
          <Fragment>
            <div className="card mx-4">
            <div className="m-3"><h3>Counsellor</h3></div>
            <div className='mx-3'><hr /></div>
              <div style={{alignItems:"center"}} className='dynamic-space-around'>
                <div className='p-3 counsellor-image-div'>
                  <div style={{borderRadius:"50%"}} className="counsellor-border">
                    <img
                      src={counsellorImage}
                      alt="sdfsfsd"
                      className='counsellor-img'
                    />
                  </div>
                </div>
                <div style={{minWidth:"80%"}} className='p-3'>
                  <div className='counsellor-table-div' style={{width:"-webkit-fill-available"}}>
                    <table className='table'>
                      <tbody>
                        <tr>
                          <th>Name</th>
                          <td>{counsellor.name}</td>
                        </tr>
                        <tr>
                          <th>Bio</th>
                          <td>{counsellor.bio}</td>
                        </tr>
                        <tr>
                          <th>Expertise</th>
                          <td>{counsellor.expertise}</td>
                        </tr>
                        <tr>
                          <th>Experience</th>
                          <td>{counsellor.experience}</td>
                        </tr>
                        <tr>
                          <th>Fee Per Session </th>
                          <td>{counsellor.feePerSession?counsellor.feePerSession:0}₹ <span style={{color:"gray"}}> + tax</span></td>
                        </tr>
                        {counsellor.feePerSession && counsellor.feePerSession > 0 &&<tr>
                          <th>Tax</th>
                          <td><p>18% of GST collected at checkout</p></td>
                        </tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
                

              </div>
            </div>
          </Fragment>

          {/* solt information */}
          <Fragment>
            
            <div className="card m-4">
            <div className="m-3"><h3>Slot Booking</h3></div>
            <div className='mx-3'><hr /></div>
              <div className='dynamic-space-between '>
                <div className='mx-3'>
                  <select name="month" value={month}  onChange={(e)=>handleMonth(e)} className="form-control pe-5 round rounded-pill select-border">
                    <option value="0">January {new Date().getMonth()<=0?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="1">February {new Date().getMonth()<=1?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="2">March {new Date().getMonth()<=2?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="3">April {new Date().getMonth()<=3?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="4">May {new Date().getMonth()<=4?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="5">June {new Date().getMonth()<=5?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="6">July {new Date().getMonth()<=6?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="7">August {new Date().getMonth()<=7?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="8">September {new Date().getMonth()<=8?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="9">October {new Date().getMonth()<=9?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="10">November {new Date().getMonth()<=10?new Date().getFullYear():new Date().getFullYear()+1}</option>
                    <option value="11">December {new Date().getMonth()<=11?new Date().getFullYear():new Date().getFullYear()+1}</option>
                  </select>
                </div>
                <div className='d-flex mx-3 pb-3'>
                  <button onClick={()=>handleLeftRightMonth("Left")} style={{aspectRatio:1,display:"flex",alignItems:"center"}} className='mx-2 btn btn-outline-primary rounded-pill rounded-end'><BiSolidLeftArrow style={{fontSize:18}}/></button>
                  <button onClick={()=>handleLeftRightMonth("Right")} style={{aspectRatio:1,display:"flex",alignItems:"center"}} className='mx-2 btn btn-outline-primary rounded-pill rounded-start'><BiSolidRightArrow style={{fontSize:18}}/></button>
                </div>
                
              </div>
              <div className='m-3'><hr /></div>
              <div className='mx-3 mb-3'>
                {slots.data && slots.data.length>0 && slots.data.filter(compareWithMonth).length>0
                ?<div>
                  <div className='dynamic-space-around'>
                    {weeksInfo && weeksInfo.length>0 &&
                      weeksInfo.map((w,i)=>(
                        <div key={i} className={slotsForWeek(i)>0?'p-2 counsellor-border':'p-2 counsellor-border no-slot-week'} >
                          <div><h5>Week - {i+1}</h5></div>
                          <div className='d-flex'>
                            <div className='d-flex mx-2'>
                              <div> <strong>Days :</strong></div>
                              <div>
                                &nbsp;{w.weeks.length}
                                {/* {w.weeks.map((d,j)=>(
                                  <span className='mx-2' key={i+"-"+j}>{d.date}</span>
                                ))} */}
                              </div>
                            </div>
                            <div className='d-flex mx-2'>
                              <div><strong>Slots :</strong></div>
                              <div>&nbsp;{slotsForWeek(i)}</div>
                            </div>
                          </div>
                          <div>
                           <button style={{display:"flex",alignItems:"center",justifyContent:"center"}} disabled={slotsForWeek(i)>0?false:true} onClick={()=>handleViewWeekSlots(i)} className='btn btn-outline-primary rounded-pill'><FaForward /></button>
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div className='m-3'><hr /></div>

                  {weekSlots && weekSlots.length>0 && 
                  <div style={{alignItems:"center"}} className='overflow-auto d-flex mx-3 mb-5 p-5 counsellor-border'>
                    <table className='table slots-table'>
                      <thead>
                        <tr>
                          <th ><div style={{borderRadius:"6px 0px 0px 6px",textWrap:"nowrap"}}>Id</div></th>
                          <th ><div style={{borderRadius:"0px 0px 0px 0px",textWrap:"nowrap"}}>Date&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</div></th>
                          <th ><div style={{borderRadius:"0px 0px 0px 0px",textWrap:"nowrap"}}>Starts At</div></th>
                          <th ><div style={{borderRadius:"0px 0px 0px 0px",textWrap:"nowrap"}}>Duration</div></th>
                          <th ><div style={{borderRadius:"0px 6px 6px 0px",textWrap:"nowrap"}}>Book</div></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          weekSlots.map((x,i)=>(
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td style={{width:"fit-content"}}>{formatDate(new Date(x.date))}</td>
                            <td style={{width:"fit-content"}}>{formatTime(x.startsAt)}</td>
                            <td>{x.duration+" Min"}</td>
                            <td><span  onClick={()=>handleCheckout(counsellor.feePerSession,x.slotBookingId)} style={{background:"#00387d",color:"white"}} className='btn rounded-pill'>Book</span></td>
                          </tr>

                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                  }
                  
                
                </div>
                : <div style={{textAlign:"center",textWrap:"pretty", width:"-webkit-fill-available",minHeight:"30vh",justifyContent:"center",alignItems:"center"}} className='d-flex m-3 my-5 counsellor-border'><h3>No Slots Available For This Month</h3></div>
                }
              </div>


            </div>

            
          </Fragment>
        </div>

         {/* Payment section */}
         <Fragment>
         <button ref={buttonRef} style={{display:"none",backgroundColor:"#00387d",color:"#fff",marginBottom:"22px",width:"fit-content"}} data-bs-toggle="offcanvas" data-bs-target="#counsellorOffcanvasTop" aria-controls="offcanvasTop" className=" btn btn-info text-center rounded-pill "></button>
          <div className="offcanvas offcanvas-top" data-bs-scroll="true" id="counsellorOffcanvasTop" aria-labelledby="offcanvasTopLabel">
            <div className="offcanvas-header">
              <h2 className="offcanvas-title" id="offcanvasTopLabel">Payment</h2>
              <button ref={closeBtnRef} type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body small" style={{padding:"10px 10px 40px"}}>
              <div className="col-12">
                <div style={{justifyContent:'center'}} className='row col-12'>
                  {/* Payment */}
                  <div className="card-div col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div  className='card' >
                      <div className="card-header">
                        <h4>Methods</h4>
                      </div>
                      <div className="card-body">
                        <div className="accordion" id="accordionExample">
                          <div>
                            <h2 className="accordion-header" id="headingOne">
                              <button className="accordion-button rounded-pill collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                               UPI
                              </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <div className="dynamic-space-around">
                                  <button onClick={handlePaymentDone} className='btn btn-outline-primary fit-content'>Phone Pay</button>
                                  <button onClick={handlePaymentDone} className='btn btn-outline-primary fit-content'>Razor Pay</button>
                                  <button onClick={handlePaymentDone} className='btn btn-outline-primary fit-content'>Google Pay</button>
                                  <button onClick={handlePaymentDone} className='btn btn-outline-primary fit-content'>Paytm</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <h2 className="accordion-header" id="headingTwo">
                              <button className="accordion-button rounded-pill collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Debit Card
                              </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                              <div className='counsellor-filter-checkbox-container'>
                                <h4>Debit card payment info</h4>
                              </div>
                                
                              </div>
                            </div>
                          </div>
                          <div>
                            <h2 className="accordion-header" id="headingThree">
                              <button className="accordion-button rounded-pill collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Credit card
                              </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                              <div className="accordion-body">
                                <h4>Credit card payment info</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-12 d-flex flex-row-reverse">
                  
                    
                </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
        <Footer/>
      </Fragment>
    </div>
  )
}

export default CounsellorInformation;