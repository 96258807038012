import React, { useState, useRef } from "react";
import "./guardianPortal.css";

import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import LineChart from "../../components/lineChart/LineChart";
import PieChart from "../../components/pieChart/PieChart";

const GuardianPortal = () => {
  const [name, setName] = useState("");
  const [parnumber, setParnumber] = useState("");
  const [message, setMessage] = useState("");
  const [Connectwithexpertfeedback, setConnectwithexpertfeedback] =
    useState("");
  const expertFeedbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [number, setNumber] = useState("");
  const [feedback, setFeedback] = useState("");
  const feedbackTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const handleSubmitCall = (e: any) => {
    e.preventDefault();
    if (number.trim() === "" || number.length !== 10) {
      alert("Please enter your number");
      return;
    }
    setFeedback("Thank you! Our team will connect with you soon.");
    console.log("Mobile Number:", number);
    if (feedbackTimeoutRef.current) {
      clearTimeout(feedbackTimeoutRef.current);
    }

    // Set a timeout to clear the feedback message after 5 seconds
    feedbackTimeoutRef.current = setTimeout(() => {
      setFeedback("");
    }, 5000);
  };

  const Connectwithexpert = (e: any) => {
    e.preventDefault();
    if (parnumber.trim() === "" || parnumber.length !== 10) {
      alert("Please enter your number");
      return;
    }
    if (name.trim() === "" || name.length === 0) {
      alert("Please enter your number");
      return;
    }
    if (message.trim() === "" || message.length === 0) {
      alert("Please enter your number");
      return;
    }
    setConnectwithexpertfeedback(
      "Thank you very much for providing your details. Our expert will be in touch with you shortly."
    );
    console.log(parnumber, name, message);

    if (expertFeedbackTimeoutRef.current) {
      clearTimeout(expertFeedbackTimeoutRef.current);
    }

    // Set a timeout to clear the expert feedback message after 5 seconds
    expertFeedbackTimeoutRef.current = setTimeout(() => {
      setConnectwithexpertfeedback("");
    }, 5000);
  };

  const handleSosClick = () => {
    window.open("/emergency", "_blank");
  };
  const mentalHealthClick = () => {
    window.open("/mentalHealth", "_blank");
  };
  const physicalHealthClick = () => {
    window.open("/physicalHealth", "_blank");
  };
  const sleepPatternClick = () => {
    window.open("/sleepPattern", "_blank");
  };
  const bMIClick = () => {
    window.open("/bMI", "_blank");
  };

  const [sosShow, setSosShow] = useState(false);
  const sosNotification = () => {
    if (sosShow === true) {
      setSosShow(false);
    } else {
      setSosShow(true);
    }
  };

  return (
    <div>
      <Header />

      <div className="container py-5">
        {/* GUARDIAN ANGEL start */}
        <div className="row container">
          <div className="col-7">
            <h1 className="guardian_portal d-flex justify-content-end ">
              {/* Guardian Portal */}
            </h1>
          </div>
          <div className="col-4">
            <div className="d-flex justify-content-end ">
              <div className="circle ">
                <span className="guardian-angel">
                  <a className="guardian-angle-title" href="/guardianangel">
                    GUARDIAN ANGEL
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* GUARDIAN ANGEL end */}

        {/* Academic Report of Ward start */}

        <div className="container guardian-portal-section-contact just mb-5  mt-5">
          <div className="row">
            <h2 className="section-title mb-5">Academic Report of Ward</h2>
          </div>

          <div className="row d-flex justify-content-center text-center ">
            <div className="piechart-GuardianPortal col-md-6 col-11 mb-3">
              <div className="card w-100 p-5">
                <PieChart />
              </div>
            </div>
            <div className="card graph-GuardianPortal col-md-6 col-11 mb-3">
              <div className=" w-100 py-5 my-5">
                <h5>Data</h5>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center text-center">
            <div className="card graph-GuardianPortal col-md-6 col-11 mb-3">
              <div className=" w-100 py-5 my-5">
                <LineChart />
              </div>
            </div>
            <div className="card graph-GuardianPortal col-md-6 col-11 mb-3">
              <div className=" w-100 py-5 my-5">
                <h5>Data</h5>
              </div>
            </div>
          </div>
        </div>
        {/* Academic Report of Ward end */}

        {/* Mental Health Report start */}

        <div className="guardian-portal-section-contact mb-5">
          <h2 className="section-title">Mental Health Report</h2>
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <h3>Mood and Emotion Insights</h3>
              <p>Details and insights about the current emotional state.</p>
            </div>

            <div className="col-12 col-md-6 mb-3">
              <h3 className="text-center">Current Health Report</h3>

              <div className="container text-center">
                <div className="row ">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-6 col-12 mb-4">
                        <div className="card" onClick={mentalHealthClick}>
                          <div className="health-image">
                            <img
                              src="https://media.istockphoto.com/id/1479494606/vector/mental-health-concept.jpg?s=612x612&w=0&k=20&c=frJSKdP-5fNTvhqHEuSFVB47wxMRH_y866ebJhuUTTU="
                              alt="Mental Health"
                              className="card-img-top"
                            />
                          </div>

                          <div className="card-body">
                            <h5 className="card-title">Mental Health</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="card" onClick={physicalHealthClick}>
                          <div className="health-image">
                            <img
                              src="https://media.istockphoto.com/id/1479494606/vector/mental-health-concept.jpg?s=612x612&w=0&k=20&c=frJSKdP-5fNTvhqHEuSFVB47wxMRH_y866ebJhuUTTU="
                              alt="Physical Health"
                              className="card-img-top"
                            />
                          </div>

                          <div className="card-body">
                            <h5 className="card-title">Physical Health</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="card" onClick={sleepPatternClick}>
                          <div className="health-image">
                            <img
                              src="https://media.istockphoto.com/id/1479494606/vector/mental-health-concept.jpg?s=612x612&w=0&k=20&c=frJSKdP-5fNTvhqHEuSFVB47wxMRH_y866ebJhuUTTU="
                              alt="Sleep Pattern"
                              className="card-img-top"
                            />
                          </div>

                          <div className="card-body">
                            <h5 className="card-title">Sleep Pattern</h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6  mb-4">
                        <div className="card" onClick={bMIClick}>
                          <div className="health-image">
                            <img
                              src="https://media.istockphoto.com/id/1479494606/vector/mental-health-concept.jpg?s=612x612&w=0&k=20&c=frJSKdP-5fNTvhqHEuSFVB47wxMRH_y866ebJhuUTTU="
                              alt="BMI"
                              className="card-img-top"
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="card-title">BMI</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mental Health Report end */}

        {/* SOS Button start */}
        <div className="row   mb-5 text-center ">
          <div className="col-md-6 col-8 mb-2">
            <button
              className="btn btn-danger    w-50 text-center"
              onClick={handleSosClick}
            >
              SOS Button
            </button>
          </div>
          <div className="col-md-6 col-4 mb-2">
            {/* <button
              className="btn btn-info btn-lg w-25 text-center"
              onClick={sosNotification}
            >
              <i className=" bi bi-bell-fill"></i>
            </button> */}
            <div
              className="text-center d-flex justify-content-end col-6"
              onClick={sosNotification}
            >
              <i className="circle-bell  bi bi-bell-fill"></i>
            </div>
          </div>
          {sosShow && (
            <div>
              <div className="modalOverlay" onClick={sosNotification}>
                <div className="modal" onClick={(e) => e.stopPropagation()}>
                  <button
                    className="modalCloseButton"
                    onClick={sosNotification}
                  >
                    X
                  </button>
                  <h1> All alert hello</h1>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* SOS Button start */}

        {/* all  connect  start */}
        <div className=" ">
          <div className="guardian-portal-section-contact mb-5 ">
            <h2 className="section-title">Connect</h2>
            <div className=" row gap-3  mb-5 justify-content-center">
              <div className="col-12 col-md-5  ">
                <div className="card text-center">
                  <div className="card-body">
                    <p>
                      <i className="bi bi-telephone-fill m-2"></i>Call Us
                    </p>
                    <div className="d-flex justify-content-center">
                      <input
                        type="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        className="w-75  form-control mb-2  "
                        placeholder="Enter your number"
                        required
                      />
                    </div>
                    <button
                      className="btn btn-primary w-50   mb-3"
                      onClick={handleSubmitCall}
                    >
                      OK
                    </button>
                    {feedback && (
                      <p className="alert alert-success">{feedback}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-5">
                <div className="card  text-center ">
                  <div className="card-body">
                    <i className="bi bi-chat-dots-fill  m-2 "></i>Chat with Us
                    <div className="text-center">
                      <button className="btn btn-secondary w-50 m-5">
                        Open Chat
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* </div> */}
            <div className="justify-content-center m-md-3 ">
              <div className="card m-md-5">
                <div className="row d-flex   m-md-3 p-1 p-md-5    connect-expert-container">
                  <h3 className="text-center section-title  text-bold mb-5">
                    Connect with an Expert
                  </h3>
                  <div className="col-12 col-lg-6 text-center mb-5">
                    <img
                      // className="w-md-100 w-100 connect-expert-img"
                      className="  expert-image"
                      src="https://www.foqas.com/static/web/img/tech/techvector.png"
                      alt=""
                      // style={{ height: "250px", width: "auto" }}
                      // style={{
                      //   height: "250px",
                      //   maxWidth: "100%",
                      //   width: "auto",
                      //   objectFit: "cover",
                      // }}
                    />
                  </div>

                  <div className="col-12 col-lg-6 mb-2">
                    <div style={{ height: "320px", width: "auto" }}>
                      <div className="row mb-2">
                        {/* <div className="col-12 col-md-3  connect-expert-label">
                        Name:
                       </div> */}
                        <div className="col-12 col-lg-9  w-100 ">
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            placeholder="Name"
                            className="form-control connect-expert-input "
                          />
                        </div>
                      </div>

                      <div className="row mb-2">
                        {/* <div className="col-12 col-md-3 connect-expert-label">
                        Mobile:
                      </div> */}
                        <div className="col-12 col-lg-9  w-100">
                          <input
                            type="number"
                            value={parnumber}
                            onChange={(e) => setParnumber(e.target.value)}
                            required
                            placeholder="Contact Number"
                            className="form-control  connect-expert-input"
                          />
                        </div>
                      </div>

                      <div className="row">
                        {/* <textarea
                        className="form-control  connect-expert-textarea "
                        placeholder="Reason for Connection"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      ></textarea> */}

                        <div className="text-center d-flex justify-content-center  mb-md-2 mt-0 mb-0 ">
                          <textarea
                            className="form-control w-md-100  connect-expert-textarea"
                            placeholder="Reason to connect with an expert"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                          ></textarea>
                        </div>
                      </div>

                      <button
                        className="w-100 connect-expert-button"
                        onClick={Connectwithexpert}
                      >
                        Connect with Expert
                      </button>

                      {Connectwithexpertfeedback && (
                        <p className="alert alert-success">
                          {Connectwithexpertfeedback}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* all  connect  end */}

        <div className="guardian-portal-section-contact">
          <h2 className="section-title">Activities and Events</h2>
          <p>
            Upcoming activities and events relevant to the ward's development.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GuardianPortal;
