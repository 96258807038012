import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {User,LoginData,AuthState} from '../interface'

export const userData = localStorage.getItem('user');
let parsedUser: User | null = null;
if (userData){
  parsedUser = JSON.parse(userData) as User;
}

export interface Errors {
  contact?: string;
  password?: string;
}

export const initialLoginData :LoginData={
  contact:'',
  email: '',
  username: '',
  phoneNumber: '',
  password: ''
}

export const initialState: AuthState = {
  user: parsedUser,
  accessToken: null,
  refreshToken: null,
  loading: false,
  status: 'idle',
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<{ reqData: LoginData, navigate: NavigateFunction }>) => {
      state.loading = true;
      state.status = 'loading';
      
    },
    loginSuccess: (state, action: PayloadAction<{ user: User; accessToken: string; refreshToken: string }>) => {
      debugger;
      state.status = 'succeeded';
      state.user = action.payload.user;
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.loading = false;
      state.error = null;
    },
    loginFailure: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      debugger;
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
    logoutFailure: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.loading = false;
      state.error = action.payload;
    },
  },
});



export const { loginRequest, loginSuccess, loginFailure, logout,logoutFailure, } = authSlice.actions;
export default authSlice.reducer;

