import { Mentadisorder } from '../store/interface';
import axios from './_dotnetConfiguration';

export const getMentalHealthIssuesAndItsTypes = async() =>{
    
    return await axios.get<Mentadisorder[]>('/HealthIssue/GetMentalHealthIssuesAndItsTypes');
}




