import React from 'react'
import ReactPaginate from 'react-paginate'
import './pagination.css'

const Pagination: React.FC<any> = ({currentPageNumber,totalPageCount,handlePageChange}) => {

    const OnpageChange = (data:any)=>{
        handlePageChange(data);
    }
    return (
        <ReactPaginate
        previousLabel={'Previous'}
        nextLabel={'Next'}
        breakLabel={'...'}
        pageCount={totalPageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={OnpageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
        pageClassName={'page-item'}
        pageLinkClassName={'page-link'}
        previousClassName={'page-item'}
        previousLinkClassName={'page-link'}
        nextClassName={'page-item'}
        nextLinkClassName={'page-link'}
        breakClassName={'page-item'}
        breakLinkClassName={'page-link'}
        forcePage={currentPageNumber}
      />
    )
}

export default Pagination