import React, { useState } from "react";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
const SleepPattern = () => {
  const userWeight = 70;
  return (
    <div>
      <Header />
      <div className="container">
        <h1 className="text-center ">Sleep Pattern</h1>

        <div className=" institute-portal-section-contact  mb-5  mt-5">
          <div className="row d-flex justify-content-center text-center ">
            <div className="card pb-5 piechart-GuardianPortal col-lg-6 col-md-7  col-12 mb-3">
              <div className="circular-progress-bar w-100 ">
                {/* Line chart */}
              </div>
            </div>
            <div className="card graph-institute-portal col-lg-6 col-md-5 col-12 mb-3">
              <div className=" w-100 py-5 my-5">
                <h5>data</h5>
                {`${userWeight} kg`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SleepPattern;
