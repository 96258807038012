import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";

// Register the chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart: React.FC = () => {
  // Data for the chart
  const data: ChartData<"bar", number[], string> = {
    labels: ["Class 1", "Class 2", "Class 3", "Class 4", "Class 5"], // X-axis labels
    datasets: [
      {
        label: "Percentage (%)", // Name of the dataset
        data: [85, 72, 90, 65, 88], // Percentage data for each class
        backgroundColor: "rgba(75, 192, 192, 0.6)", // Bar color
        borderColor: "rgba(75, 192, 192, 0)", // No border
        borderWidth: 0, // Removes the border
      },
    ],
  };

  // Configuration options for the chart
  const options: ChartOptions<"bar"> = {
    responsive: true, // Makes the chart responsive to screen size
    plugins: {
      legend: {
        display: false, // Hide the legend
        position: "top",
      },
      title: {
        display: false, // Hide the title
      },
    },
    scales: {
      y: {
        beginAtZero: true, // Y-axis starts from zero
        max: 100,
        grid: {
          display: false, // Hides grid lines on Y-axis
        },
        title: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false, // Hides grid lines on X-axis
        },
        title: {
          display: false,
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
