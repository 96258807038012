import React from 'react'
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

const RefundPolicy: React.FC = () => {
    return (
        <div>
        <Header />
        <div className="container">
          <div className="d-flex text-center justify-cotent-center">
            <h1 className=" mt-5 ">Refund Policy</h1>
          </div>
          <hr style={{border:"3px solid"}}/>
          <div className="md-mb-4 md-p-5">
            <ul><li><h4>At YouEvolve, we strive to provide a seamless and satisfying experience with our services. However, we understand that certain situations may arise that require a refund. Below are the conditions under which we offer refunds:</h4></li></ul>
            <br/>
            <ol>
                <li><h5>Payment Processing Issues</h5></li>
                <ul><li><h6>If the amount you paid has not been processed by the bank or if YouEvolve has not received the funds, the refund will be handled directly by the bank or payment gateway used. YouEvolve will not be responsible for processing such refunds as the payment did not reach us.</h6></li></ul>
                <br />


                <li><h5>Service Activation Failure</h5></li>
                <ul>
                    <li><h6>In the event that you have successfully completed the payment but the subscription or services have not been activated within 72 hours, you are eligible for a full refund of the amount paid.</h6></li>
                    <li><h6>To request a refund, please contact our support team within 7 days from the date of payment. Refund requests made after this period may not be considered.</h6></li>
                </ul>
                <br />


                <li><h5>Change of Mind</h5></li>
                <ul><li><h6>We do not provide refunds if you simply change your mind after subscribing to our services. We encourage users to fully explore any free trials or demo versions of the services before making a purchase.</h6></li></ul>
                <br />


                <li><h5>Subscription Cancellation</h5></li>
                <ul><li><h6>If you cancel your subscription mid-term, you will continue to have access to the services until the end of the current billing cycle. We do not offer refunds for unused portions of your subscription.</h6></li></ul>
                <br />


                <li><h5>Technical Issues</h5></li>
                <ul><li><h6>If you experience technical issues that prevent you from using the services and our support team is unable to resolve the problem within a reasonable timeframe, you may be eligible for a refund on a pro-rata basis, depending on the amount of time the service was unavailable.</h6></li></ul>
                <br />


                <li><h5>Refund Request Process</h5></li>
                <ul>
                    <li><h6>All refund requests must be submitted through our support system with proof of payment and relevant details regarding the issue.</h6></li>
                    <li><h6>Refunds, once approved, will be processed within 10 business days. The actual time to reflect the amount in your account may vary depending on your bank or payment provider.</h6></li>
                </ul>
                <br />


                <li><h5>Eligibility for Refunds</h5></li>
                <ul><li><h6>Refunds are only available for payments made directly to YouEvolve via our official platforms. Any third-party purchases or transactions made outside of YouEvolve’s official systems are not eligible for refunds through YouEvolve.</h6></li></ul>
                <br />


                <li><h5>No Refund for Violations of Terms</h5></li>
                <ul><li><h6>Refunds will not be provided to users who violate YouEvolve’s Terms of Service, including any misuse of the platform, breach of agreements, or involvement in fraudulent activities.</h6></li></ul>
                <br />


                <li><h5>Changes to the Refund Policy</h5></li>
                <ul><li><h6>YouEvolve reserves the right to modify or update this refund policy at any time. Changes will be posted on our website and app, and users will be informed accordingly. It is your responsibility to review the refund policy periodically.</h6></li></ul>
                <br />

                


            </ol>
            <ul><li><h5>For further assistance or to initiate a refund request, please reach out to our support team at <a href="mailto:support@youevolve.in">support@youevolve.in</a>.</h5></li></ul>
          </div>
        </div>
        <Footer />
      </div>
    )
};

export default RefundPolicy;