

import React, { useState } from 'react';
import Pagination from '../../components/pagination/Pagination';
import "./test.css"

interface Item {
  id: number;
  name: string;
}

const Test: React.FC = () => {
  // Sample list of items (you can replace this with data from an API)
  const items: Item[] = Array.from({ length: 50 }, (_, i) => ({
    id: i + 1,
    name: `Item ${i + 1}`,
  }));

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Items per page
  const itemsPerPage = 5;

  // Calculate the current items to display based on currentPage
  const offset = currentPage * itemsPerPage;
  const currentItems = items.slice(offset, offset + itemsPerPage);

  // Handle page change event
  const handlePageChange = (selectedItem: { selected: number }) => {
    debugger;
    setCurrentPage(selectedItem.selected);
  };

  return (
    <div style={{background:"green"}}>
      <h2>Paginated List</h2>
      <div>
            <h1>Hello, world!</h1>
        </div>
      <div>
      
      <div className="emoji"><span title="happy" >😀</span></div>
      <span title="sad" className="emoji">😔</span>
      <span title="angry" className="emoji">😡</span>
      <span title="tearful" className="emoji">😢</span>
      <span title="tired" className="emoji">😫</span>
      <span title="anxious" className="emoji">😨</span>
      <span title="depressed" className="emoji">😰</span>
      <span title="excited" className="emoji">🤩</span>
      <span title="numb" className="emoji">😮</span>
      <span title="worried" className="emoji">😟</span>
      <span title="relieved" className="emoji">😮‍💨</span>
      <span title="frustrated" className="emoji">😤</span>
      <span title="content" className="emoji">😇</span>
      <span title="overwhelmed" className="emoji">🤯</span>
      <span title="vulnerable" className="emoji">😕</span>
      <span style={{ fontSize: '24px' }}>😀</span>
      <span style={{ fontSize: '36px' }}>😀</span>
      <span style={{ fontSize: '111px' }}>😀</span>
    </div>
      <ul>
        {currentItems.map(item => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>
      

      <Pagination pageCount={10} handlePageChange={handlePageChange}/>

      
    </div>
  );
};

export default Test;


