import React from 'react'
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

const CancellationPolicy: React.FC = () => {
    return (
        <div>
        <Header />
        <div className="container">
          <div className="d-flex text-center justify-cotent-center">
            <h1 className=" mt-5 ">Cancellation Policy for YouEvolve Subscriptions</h1>
          </div>
          <hr style={{border:"3px solid"}}/>
          <div className="md-mb-4 md-p-5">
           <ol>
                
                <li><h5>Subscription Types</h5></li>
                <ul>
                    <li><h6>Monthly Subscription: Recurs every 30 days.</h6></li>
                    <li><h6>Quarterly Subscription: Recurs every 90 days.</h6></li>
                    <li><h6>Annual Subscription: Recurs every 365 days.</h6></li>
                </ul>
                <br />

                <li><h5>How to Cancel a Subscription</h5></li>
                <ul>
                    <li><h6>You can cancel your subscription at any time by visiting your YouEvolve account dashboard or by contacting our support team at [Support Email]. Cancellations will take effect at the end of the current billing cycle. You will continue to have access to the service until the subscription term ends.</h6></li>
                    <li><h6>You must cancel before the renewal date to avoid being charged for the next billing cycle.</h6></li>
                </ul>
                <br />

                <li><h5>Cancellation Window</h5></li>
                <ul>
                    <li><h6>Monthly Subscription: You can cancel anytime before the renewal date. Cancellation will take effect at the end of the current month.</h6></li>
                    <li><h6>Quarterly Subscription: You can cancel anytime before the renewal date. Cancellation will take effect at the end of the current quarter.</h6></li>
                    <li><h6>Annual Subscription: You can cancel anytime before the renewal date. Cancellation will take effect at the end of the current year.</h6></li>
                </ul>
                <br />

                <li><h5>Refunds After Cancellation</h5></li>
                <ul>
                    <li><h6>We do not offer pro-rated refunds for canceled subscriptions. Once a subscription term has begun, it will run until the end of the billing period.</h6></li>
                    <li><h6>If your subscription includes a free trial period, you will not be charged until the trial period ends. Cancelling during the trial period will prevent any charges.</h6></li>
                </ul>
                <br />

                <li><h5>Automatic Renewals</h5></li>
                <ul>
                    <li><h6>All subscriptions are set to renew automatically at the end of their billing cycle. If you do not wish to continue, you must cancel the subscription before the renewal date.</h6></li>
                    <li><h6>YouEvolve is not responsible for charges resulting from failure to cancel your subscription before the renewal date.</h6></li>
                </ul>
                <br />

                <li><h5>Upgrading or Downgrading a Subscription</h5></li>
                <ul>
                    <li><h6>If you choose to upgrade your subscription (e.g., from monthly to annual), the new plan will take effect immediately, and any unused portion of the current plan will be credited toward the new plan.</h6></li>
                    <li><h6>If you choose to downgrade your subscription, the change will take effect at the end of the current billing cycle, and you will be billed at the lower rate from the next billing cycle onward.</h6></li>
                </ul>
                <br />

                <li><h5>Reactivation After Cancellation</h5></li>
                <ul><li><h6>If you decide to reactivate your YouEvolve subscription after cancellation, you may do so by logging into your account and selecting a new plan. Reactivation will be treated as a new subscription and will not be backdated.</h6></li></ul>
                <br />

                <li><h5>Impact of Cancellation on Your Data</h5></li>
                <ul>
                    <li><h6>Upon canceling your subscription, your access to YouEvolve services will continue until the end of your billing cycle.</h6></li>
                    <li><h6>After the subscription expires, your account will revert to a limited/free version, and your data will be preserved for a period of 90 days. During this time, you can still log in and access limited features but won’t be able to use premium services unless you reactivate your subscription.</h6></li>
                    <li><h6>After the 90-day grace period, YouEvolve reserves the right to permanently delete your data from our servers.</h6></li>
                </ul>
                <br />

                <li><h5>Refund Policy Exceptions</h5></li>
                <ul>
                    <li><h6>If YouEvolve fails to deliver the service as per our Service Level Agreement (SLA) or there is a technical issue preventing you from using the service for more than 48 consecutive hours, we will evaluate refund requests on a case-by-case basis.</h6></li>
                    <li><h6>Any request for refunds under this clause should be directed to our support team at [Support Email] within 7 days of the service disruption.</h6></li>
                </ul>
                <br />

                <li><h5>Special Offers & Discounts</h5></li>
                <ul>
                    <li><h6>Any subscriptions purchased under promotional pricing or discounts are subject to the same cancellation terms. Canceling such subscriptions will forfeit the promotional pricing, and reactivation at a later time will revert to standard pricing.</h6></li>
                </ul>
                <br />

                <li><h5>Changes to the Cancellation Policy</h5></li>
                <ul><li><h6>YouEvolve reserves the right to modify or update this cancellation policy at any time. Any changes will be posted on our website, and it is the customer’s responsibility to review the policy regularly. Continued use of the service after changes have been made constitutes acceptance of the new terms.</h6></li></ul>
                <br />

           </ol>
          </div>
        </div>
        <Footer />
      </div>

    )
};

export default CancellationPolicy;