import { useState, useEffect } from "react";
import HomeBanner from "../../../components/homeBanner/HomeBanner";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
// import img from "../../../assets/images/circleweb.png";
import "./nationalStandingNew.css";
import noUserimg from "../../../assets/images/logo.jpg";
import { LeaderboardResponse } from "../../../store/interface";
import { getLeaderboard } from "../../../api/leaderboard";
import Loader from "../../../components/loader/Loader";
// const nationalData = [
//   { _id: "1", place: 1, userName: "sudhanshu  singh singh", points: 180 },
//   {
//     _id: "2",
//     place: 2,
//     images: img,
//     userName: "sudhanshu  singh",
//     points: 180,
//   },
//   { _id: "3", place: 3, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "4", place: 4, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "5", place: 5, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "6", place: 6, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "7", place: 7, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "8", place: 8, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "9", place: 9, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "10", place: 10, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "11", place: 11, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "12", place: 12, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "13", place: 13, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "14", place: 14, images: img, userName: "sudhanshu", points: 180 },
//   { _id: "15", place: 15, images: img, userName: "sudhanshu", points: 180 },
// ];

const NationalStandingNew: React.FC = () => {
  const initialleaderboad: LeaderboardResponse = {
    statusCode: 0,
    data: [],
    message: "",
    isSuccessful: false,
  };
  const [leaderboardData, setLeaderboardData] =
    useState<LeaderboardResponse>(initialleaderboad);
  const [visibleUserId, setVisibleUserId] = useState<string | null>(null);

  const handleClick = (id: number) => {
    setVisibleUserId(visibleUserId === id.toString() ? null : id.toString());
  };

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        debugger;
        setLoader(true);
        const response = await getLeaderboard();
        // const sortedData = response.data.sort(
        //   (a: any, b: any) => a.rank - b.rank
        // );
        // setLeaderboardData(sortedData);
        setLeaderboardData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchLeaderboardData();
  }, []);

  const [loader, setLoader] = useState<boolean>(false);

  return (
    <div className="bg-light">
      {loader && <Loader />}
      <HomeBanner />
      <Header />

      <div className="d-flex container-fluid justify-content-center px-5 ">
        <div className="m-2">
          <h1 className="nationalStanding text-center mt-5 mb-5 ">
            National Standing
          </h1>

          <div className="row  nationalcontainer ">
            {/* Header Row */}
            <div className="nationaluserHeader  d-flex gap-3 justify-content-center bg-primary text-white py-2  rounded">
              <div className="col-2  text-left nationadataplace ">Ranking</div>
              <div className="col-6 text-center  nationadatauserName">
                Username
              </div>
              <div className="col-2 text-center">Points</div>
            </div>
            {/* Data Rows */}
            <div className="alluserData ">
              {leaderboardData.data.map((nationadata: any) => (
                <div
                  key={nationadata.userId}
                  className="nationaluser d-flex gap-3 justify-content-center"
                >
                  {/* Rank will show */}
                  <div className="col-2  fw-bold nationadataplace">
                    <div className="d-flex gap-3">
                      <div className="ribbon_place col-1">
                        {nationadata.rank && nationadata.rank <= 1 && (
                          <div className="ribbon_new  ">
                            <i className="ribbon_new_start_first bi bi-star-fill"></i>
                          </div>
                        )}
                        {nationadata.rank && nationadata.rank === 2 && (
                          <div className="ribbon_new  ">
                            <i className="ribbon_new_start_Second bi bi-star-fill"></i>
                          </div>
                        )}
                        {nationadata.rank && nationadata.rank === 3 && (
                          <div className="ribbon_new  ">
                            <i className="ribbon_new_start_third bi bi-star-fill"></i>
                          </div>
                        )}
                      </div>
                      <div className="col-11">{nationadata.rank}</div>
                    </div>
                  </div>
                  {/* images with name  */}
                  <div className="col-6  nationadatauserName">
                    <div className="d-flex  gap-3 justify-content-center gap-2 ">
                      <div className="col-1 UserImages py-1 ">
                        <img src={noUserimg || noUserimg} alt="user" />
                      </div>

                      <div
                        className={`userName col-5 ${
                          visibleUserId === nationadata.userId?.toString()
                            ? "show-full-text"
                            : ""
                        }`}
                        onClick={() => handleClick(nationadata.userId!)}
                      >
                        {nationadata.name}
                      </div>
                    </div>
                  </div>
                  {/* Score */}
                  <div className="point col-2 fw-bold text-center ">
                    {nationadata.score}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div style={{ height: "100px" }}></div>
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-3 d-flex align-items-center">
              <label className="from">From</label>
              <input className="forminputype" type="text" />
            </div>
            <div className="col-6 col-md-3 d-flex align-items-center">
              <label className="to">To</label>
              <input className="forminputype" type="text" disabled />
            </div>
            <div className="go col-4 col-md-1  d-flex align-items-center">
              <button className="goButton py-2">Go</button>
              <label className="Questionmark">?</label>
            </div>
          </div>
        </div>
      </div>

      <div style={{ height: "100px" }}></div>

      <Footer />
    </div>
  );
};

export default NationalStandingNew;
