import { z } from 'zod';

export const loginSchema = z.object({
  contact:z.string().min(4,'Minimum four char required'),
  email: z.string().refine(value => value === '' || value === undefined || (value.length>0 &&z.string().email()), {
    message: 'Invalid email format',
  }).optional().default(''),
  username:  z.string().refine(value => value === '' || value === undefined || (value.length>0 &&z.string().min(4)), {
    message: "Minimum four char required with username",
  }).optional().default(''),
  phoneNumber:  z.string().refine(value => value === '' || value === undefined || (value.length>0 &&z.string().regex(/^([6-9][0-9]{9})$/)), {
    message: "Invalid phone number",
  }).optional().default(''),
  password: z.string().trim().min(8,'Password should have minimun 8 char length')
  .regex(/[A-Z]/,'Password must contain at least one uppercase letter')
  .regex(/[a-z]/,'Password must contain at least one lowercase letter')
  .regex(/[0-9]/,'Password must contain at least one number')
  .regex(/[!@#$%^&*]/,'Password must contain at least one special character')
  
});
// .transform(data => ({
//   username: data.username,
//   email:data.email,
//   phoneNumber:data.phoneNumber,
//   password: data.password,
// })) as z.ZodType<LoginData, any>;


export const signupValidateModel = z.object({
  email: z.string().refine(value => value === '' || value === undefined || (value.length>0 &&z.string().email()), {
    message: 'Invalid email format',
  }).optional().default(''),
  phoneNumber:  z.string().refine(value => value === '' || value === undefined || (value.length>0 &&z.string().regex(/^([6-9][0-9]{9})$/)), {
    message: "Invalid phone number",
  }).optional().default(''),
});

export type SignupValidateModel = z.infer<typeof signupValidateModel>;