
import { LoginData } from '../store/interface';
import axios from './_nodeConfigurations';

export const login = async(credentials: LoginData) =>{
  return await axios.post('/users/login', credentials);
}

export const signup = async(details: { username: string; password: string }) =>{
  return await axios.post('/users/signup', details);
}

export const logout =async()=>{
  debugger;
  return await axios.post(`/users/logout`);
};

export const deleteAccount = async () =>{
  debugger;
  return await axios.post('/users/deleteAccount');
}
