// import React from "react";
// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css"; // Import the default styles

// interface CircularProgressBarProps {
//   percentage: number; // Average marks percentage
// }

// const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
//   percentage,
// }) => {
//   const getLabel = (percentage: number) => {
//     if (percentage >= 90) return "Best";
//     if (percentage >= 75) return "Good";
//     if (percentage >= 50) return "Average";
//     if (percentage >= 25) return "Bad";
//     return "Worst";
//   };

//   const getColor = (percentage: number) => {
//     if (percentage >= 90) return "#4caf50"; // Green
//     if (percentage >= 75) return "#8bc34a"; // Light Green
//     if (percentage >= 50) return "#ffc107"; // Yellow
//     if (percentage >= 25) return "#ff9800"; // Orange
//     return "#f44336"; // Red
//   };

//   return (
//     <div style={{ width: "200px", height: "200px" }}>
//       <CircularProgressbar
//         value={percentage}
//         text={`${percentage}%`}
//         styles={buildStyles({
//           pathColor: getColor(percentage),
//           textColor: "#000",
//           trailColor: "#d6d6d6",
//           strokeLinecap: "round",
//         })}
//       />
//       <div style={{ textAlign: "center", marginTop: "10px" }}>
//         <span>{getLabel(percentage)}</span>
//       </div>
//     </div>
//   );
// };

// export default CircularProgressBar;

import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css"; // Import the default styles

interface CircularProgressBarProps {
  percentage: number; // Average marks percentage
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  percentage,
}) => {
  const getLabel = (percentage: number) => {
    if (percentage >= 90) return "Best";
    if (percentage >= 75) return "Good";
    if (percentage >= 50) return "Average";
    if (percentage >= 25) return "Bad";
    return "Worst";
  };

  const getColor = (percentage: number) => {
    if (percentage >= 90) return "#4caf50"; // Green
    if (percentage >= 75) return "#8bc34a"; // Light Green
    if (percentage >= 50) return "#ffc107"; // Yellow
    if (percentage >= 25) return "#ff9800"; // Orange
    return "#f44336"; // Red
  };

  return (
    <div
      style={{
        width: "200px",
        height: "200px",
        fontSize: "25px",
      }}
    >
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          pathColor: getColor(percentage),
          textColor: "#000",
          trailColor: "#d6d6d6",
          strokeLinecap: "round",
        })}
      />
      <div
        style={{
          textAlign: "center",
          marginTop: "15px",
          fontSize: "30px",
        }}
      >
        <span className="">{getLabel(percentage)}</span>
      </div>
    </div>
  );
};

export default CircularProgressBar;
