import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

const LeaderBoardScreen: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Home");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleTabClick = (tabName: string) => {
    setActiveTab(tabName);
  };

  const handleNationalStandingClick = () => {
    navigate("/nationalStandingScreen");
  };

  const styles: {
    [key: string]: React.CSSProperties | { [key: string]: React.CSSProperties };
  } = {
    app: {
      textAlign: "center",
      maxWidth: "1500px",
      margin: "0 auto",
    },
    appHeader: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "15px",
      backgroundColor: "rgba(6, 43, 89, 0.55)",
      color: "white",
      alignItems: "center",
      width: "80%",
      borderBottomLeftRadius: "12px",
      borderBottomRightRadius: "12px",
      margin: "0 auto",
    },
    downloadappHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "20px",
      justifyContent: "space-evenly",
      backgroundColor: "#0012B5",
      color: "white",
    },
    headerText: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    navLink: {
      margin: "0 10px",
      color: "white",
      textDecoration: "none",
      cursor: "pointer",
      padding: "5px 0",
    },
    activeLink: {
      margin: "0 10px",
      color: "white",
      textDecoration: "none",
      cursor: "pointer",
      padding: "5px 0",
      borderBottom: "2px solid white",
    },
    appDownloadButton: {
      backgroundColor: "#FCEE21",
      border: "none",
      padding: "10px",
      cursor: "pointer",
      borderRadius: "16px",
      fontSize: "16px",
      color: "#000000",
      fontWeight: "bold",
    },
    profileIconnew: {
      height: "40px",
      width: "40px",
      borderRadius: "40px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "10px",
    },
    modal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#747474",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      zIndex: 1000,
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 999,
    },
    modalCloseButton: {
      cursor: "pointer",
      backgroundColor: "red",
      color: "white",
      border: "none",
      borderRadius: "5px",
      padding: "5px 10px",
      float: "right",
    },
    addcart: {
      height: "30px",
      width: "30px",
      borderRadius: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: "15px",
    },
    disorderContainer: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "600px", // Fixed height for the scrollable area
      overflowY: "scroll", // Enables vertical scrolling
      backgroundColor: "#0012B5", // Background color for the scrollable area
      borderRadius: "10px",
      scrollbarWidth: "none",
    },
    disorderItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      padding: "10px",
      marginBottom: "10px",
      marginTop: "20px",
      // backgroundColor: '#E4F0FF',
      borderRadius: "10px",
      // boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      width: "100%",
    },
    disorderImage: {
      width: "100px",
      height: "100px",
      marginBottom: "10px",
    },
    disorderName: {
      fontSize: "18px",
      fontWeight: "bold",
      color: "white",
    },
    joinButton: {
      height: "40px",
      width: "141px",
      borderRadius: "12px",
      borderWidth: 1,
      borderColor: "#00387D",
      color: "white",
      fontSize: "16px",
      fontWeight: "600",
      backgroundColor: "#0012B5",
      cursor: "pointer",
      marginBottom: "10px",
      marginTop: "6px",
    },
    footer: {
      height: "203px",
      width: "100%",
      backgroundColor: "black",
      display: "flex",
      justifyContent: "space-around",
      paddingTop: "20px",
      flexWrap: "wrap", // Make footer items wrap on smaller screens
    },
    footerItem: {
      margin: "10px",
    },
    footerText: {
      fontSize: "16px",
      color: "#A0A0A0",
      fontWeight: "normal",
      textAlign: "left",
    },
    footerLink: {
      textDecoration: "none",
    },
    "@media (max-width: 1024px)": {
      appHeader: {
        flexDirection: "column",
        alignItems: "center",
      },
      disorderItem: {
        flexDirection: "column",
      },
      disorderImage: {
        marginBottom: "10px",
      },
    },
    "@media (max-width: 768px)": {
      headerText: {
        fontSize: "20px",
      },
      appHeader: {
        width: "100%",
        padding: "10px",
      },
      navLink: {
        margin: "5px 0",
      },
      disorderItem: {
        width: "100%",
      },
    },
    "@media (max-width: 480px)": {
      headerText: {
        fontSize: "16px",
      },
      appDownloadButton: {
        fontSize: "14px",
        padding: "8px",
      },
      joinButton: {
        fontSize: "14px",
        width: "100px",
      },
      disorderName: {
        fontSize: "16px",
      },
    },
  };

  return (
    <div style={styles.app}>
      <header style={styles.downloadappHeader}>
        <div style={styles.headerText}>
          Download App To Unlock More Benefits
        </div>
        <div className="app-download">
          <button style={styles.appDownloadButton}>Download App</button>
        </div>
      </header>

      <main>
        <header style={styles.appHeader}>
          <nav style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img src="/profileImage.png" style={styles.profileIconnew} />
            </div>
            {[
              "Home",
              "Events",
              "Mental Disorders",
              "Leaderboard",
              "Merchandises",
              "Counsellors And Therapists",
              "Institute Portal",
              "Guardian Portal",
            ].map((tab) => (
              <a
                key={tab}
                href={`/${tab}`}
                style={activeTab === tab ? styles.activeLink : styles.navLink}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </a>
            ))}
            <div>
              <img src="/bookMark.png" style={styles.addcart} />
            </div>
            <div>
              <img
                src="/search.png"
                style={{ width: "35px", height: "20px" }}
              />
            </div>
          </nav>
        </header>
      </main>

      <main
        style={{ backgroundColor: "white", height: "600px", width: "100%" }}
      >
        <div>
          <h2 style={{ fontSize: "40px", color: "#1D232A", fontWeight: 600 }}>
            Leaderboard
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            marginTop: "80px",
          }}
        >
          <div
            onClick={handleNationalStandingClick}
            style={{
              height: "310px",
              width: "335px",
              backgroundColor: "white",
              borderWidth: 2,
              borderColor: "#FF8D07",
              borderStyle: "dotted",
              borderRadius: 10,
            }}
          >
            <img
              src="/indiaMap.png"
              style={{ height: "253px", width: "215px", objectFit: "contain" }}
            />
            <h2
              style={{ fontSize: "20px", color: "#4A4A4A", fontWeight: "bold" }}
            >
              National Standing
            </h2>
          </div>
          <div
            style={{
              height: "310px",
              width: "335px",
              backgroundColor: "white",
              borderWidth: 2,
              borderColor: "#FF8D07",
              borderStyle: "dotted",
              borderRadius: 10,
            }}
          >
            <img
              src="/zonal.png"
              style={{ height: "253px", width: "215px", objectFit: "contain" }}
            />
            <h2
              style={{ fontSize: "20px", color: "#4A4A4A", fontWeight: "bold" }}
            >
              Zonal Standings
            </h2>
          </div>
          <div
            style={{
              height: "310px",
              width: "335px",
              backgroundColor: "white",
              borderWidth: 2,
              borderColor: "#FF8D07",
              borderStyle: "dotted",
              borderRadius: 10,
            }}
          >
            <img
              src="/bank.png"
              style={{ height: "253px", width: "215px", objectFit: "contain" }}
            />
            <h2
              style={{ fontSize: "20px", color: "#4A4A4A", fontWeight: "bold" }}
            >
              Institutional Standings
            </h2>
          </div>
        </div>
        <div style={{ height: "60px" }} />
        <div
          style={{
            height: "107px",
            width: "1360px",
            backgroundColor: "#00387D",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            margin: "0 auto",
          }}
        >
          <h2 style={{ fontSize: "30px", color: "white", fontWeight: "bold" }}>
            get in touch with us
          </h2>
          <div
            style={{
              height: "61px",
              width: "620px",
              backgroundColor: "#CFCFCF",
              borderRadius: "32px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              style={{
                flex: 1,
                fontSize: "16px",
                border: "none",
                outline: "none",
                backgroundColor: "#CFCFCF",
                borderRadius: "32px",
                marginLeft: "30px",
                marginRight: "20px",
              }}
            />
            <div
              style={{
                height: "48px",
                width: "168px",
                borderRadius: "32px",
                backgroundColor: "white",
                marginRight: "30px",
              }}
            >
              <h2
                style={{
                  fontSize: "20px",
                  color: "#00387D",
                  fontWeight: "bold",
                }}
              >
                Let’s talk
              </h2>
            </div>
          </div>
        </div>

        <div style={{ height: "60px" }} />
        <div style={styles.footer}>
          <div>
            <a href="/homescreen" style={{ textDecoration: "none" }}>
              <h2 style={styles.footerText}>ABOUT & FAQS</h2>
            </a>
            <a href="/homescreen" style={{ textDecoration: "none" }}>
              <h2 style={styles.footerText}>PROFILE</h2>
            </a>
          </div>
          <div>
            <a href="/homescreen" style={{ textDecoration: "none" }}>
              <h2 style={styles.footerText}>INFORMATION CENTER</h2>
            </a>
            <a href="/homescreen" style={{ textDecoration: "none" }}>
              <h2 style={styles.footerText}>TERMS & CONDITIONS</h2>
            </a>
            <a href="/homescreen" style={{ textDecoration: "none" }}>
              <h2 style={styles.footerText}>PRIVACY POLICY</h2>
            </a>
          </div>
          <div>
            <h2 style={styles.footerText}>All Rights Reserved</h2>
            <h2 style={styles.footerText}>
              @ rijuvalika technologies private limited
            </h2>
          </div>
          <div>
            <a
              href="mailto:admin@youevolve.in"
              style={{ textDecoration: "none" }}
            >
              <h2 style={styles.footerText}>admin@youevolve.in</h2>
            </a>
            <a
              href="mailto:rijuvalikatechnologiespvtltd@gmail.com"
              style={{ textDecoration: "none" }}
            >
              <h2 style={styles.footerText}>
                rijuvalikatechnologiespvtltd@gmail.com
              </h2>
            </a>
            <h2 style={styles.footerText}>
              Rijuvalika Technologies Pvt Ltd
              <br />
              Amarnath Keshri Bhawan
              <br />
              Babubank, JhaJha
              <br />
              Jamui, Bihar 811308
            </h2>
          </div>
        </div>
      </main>
    </div>
  );
};

export default LeaderBoardScreen;
