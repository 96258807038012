import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./privacyPolicy.css";
const PrivacyPolicy = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <div className="d-flex text-center justify-cotent-center">
          <h1 className="mt-5">Privacy Policy for You Evolve</h1>
        </div>
        <hr style={{border:"3px solid"}}/>
        <div className="md-mb-4 md-p-5">
          <ul>
            <li>
              <h6>Effective Date: 20/09/2024</h6>
            </li>
            <li>
              <h6>Last Updated: 20/09/2024</h6>
            </li>
            <li>
              <h6>
                At You Evolve (“we”, “us”, or “our”), we value your privacy and
                are committed to protecting your personal information. This
                Privacy Policy explains how we collect, use, disclose, and
                safeguard your data when you use our mental health app. Please
                read this policy carefully. By using our app, you agree to the
                collection and use of your information in accordance with this
                Privacy Policy.
              </h6>
            </li>
          </ul>

          <ol>
            <li>
              <h5>Information We Collect:</h5>
            </li>
            <ul>
              <li>
                <h5>Personal Information:</h5>
              </li>
              <ul>
                <li>
                  <h6>
                    When you use our app, we may collect personally identifiable
                    information that you provide voluntarily. This may include:
                  </h6>
                </li>
                <ul>
                  <li>
                    <h6>- Name</h6>
                  </li>
                  <li>
                    <h6>- Email address</h6>
                  </li>
                  <li>
                    <h6>- Date of birth</h6>
                  </li>
                  <li>
                    <h6>
                      - Any personal details you provide through in-app surveys,
                      forms, or messages.
                    </h6>
                  </li>
                </ul>
              </ul>
              <li className="mt-3">
                <h5>Non-Personal Information</h5>
              </li>
              <ul>
                <li>
                  <h6>
                    We may also collect non-personal data about your use of the
                    app, such as:
                  </h6>
                </li>
                <ul>
                  <li>
                    <h6>- Device information (model, operating system)</h6>
                  </li>
                  <li>
                    <h6>- Usage statistics</h6>
                  </li>
                  <li>
                    <h6>- Analytics and performance data</h6>
                  </li>
                </ul>
              </ul>
            </ul>
            <li className="mt-3">
              <h5>How We Use Your Information</h5>
            </li>
            <ul>
              <li>
                <h6>We use your information for the following purposes:</h6>
              </li>
              <ul>
                <li>
                  <h6>
                    - To provide, improve, and personalize the app experience.
                  </h6>
                </li>
                <li>
                  <h6>
                    - To communicate with you regarding updates, support, or
                    promotional content.
                  </h6>
                </li>
                <li>
                  <h6>
                    - To analyze usage patterns and app performance for
                    optimization.
                  </h6>
                </li>
                <li>
                  <h6>
                    - To manage your account, including user authentication and
                    preferences.
                  </h6>
                </li>
              </ul>
            </ul>
            <li className="mt-3">
              <h5>Data Sharing and Disclosure</h5>
            </li>
            <ul>
              <li>
                <h6>
                  We do not sell, trade, or rent your personal information to
                  third parties. However, we may share your data with:
                </h6>
              </li>
              <ul>
                <li>
                  {/* - **Service Providers:** */}
                  <h6>
                    **Service Providers:** We may share your information with
                    third-party service providers for app functionality, such as
                    hosting, analytics, or email services.
                  </h6>
                </li>
                <li>
                  {/* **Legal Requirements:** */}
                  <h6>
                    -**Legal Requirements:** We may disclose your information if
                    required by law or in response to a valid legal process,
                    such as a subpoena or court order.
                  </h6>
                </li>
                {/* - **Business Transfers:** */}
                <li>
                  <h6>
                    - **Business Transfers:** In the event of a merger,
                    acquisition, or sale of assets, your information may be
                    transferred as part of the transaction.
                  </h6>
                </li>
              </ul>
            </ul>
            <li className="mt-3">
              <h5>Security of Your Information</h5>
            </li>
            <ul>
              <li>
                <h6>
                  We implement security measures to protect your personal data
                  from unauthorized access, alteration, or disclosure. However,
                  please note that no method of transmission over the internet
                  or electronic storage is 100% secure, and we cannot guarantee
                  its absolute security.
                </h6>
              </li>
            </ul>
            <li className="mt-3">
              <h5>Your Privacy Choices</h5>
            </li>
            <ul>
              <li>
                <h6>You have the right to:</h6>
              </li>
              <ul>
                <li>
                  <h6>
                    - Access, update, or delete your personal information within
                    the app’s settings.
                  </h6>
                </li>
                <li>
                  <h6>
                    - Opt-out of receiving promotional communications by
                    following the unsubscribe instructions in the emails or
                    contacting us directly.
                  </h6>
                </li>
                <li>
                  <h6>
                    - Restrict the collection of certain non-personal data by
                    adjusting your device’s privacy settings.
                  </h6>
                </li>
              </ul>
            </ul>
            <li className="mt-3">
              <h5>Third-Party Links</h5>
            </li>
            <ul>
              <li>
                <h6>
                  Our app may contain links to third-party websites or services.
                  We are not responsible for the privacy practices of those
                  sites and recommend that you review their privacy policies.
                </h6>
              </li>
            </ul>
            <li className="mt-3">
              <h5>Children’s Privacy</h5>
            </li>
            <ul>
              <li>
                <h6>
                  Our app is not intended for use by individuals under the age
                  of 13. We do not knowingly collect personal information from
                  children under 13. If we discover that we have collected data
                  from a child under 13, we will take steps to delete it.
                </h6>
              </li>
            </ul>
            <li className="mt-3">
              <h5>Changes to This Privacy Policy</h5>
            </li>
            <ul>
              <li>
                <h6>
                  We may update this Privacy Policy from time to time. We will
                  notify you of any changes by updating the 'Last Updated' date
                  at the top of this policy. We encourage you to review this
                  policy periodically to stay informed about how we protect your
                  information.
                </h6>
              </li>
            </ul>
            <li className="mt-3">
              <h5> Contact Us</h5>
            </li>
            <ul>
              <li>
                <h6>
                  If you have any questions or concerns about this Privacy
                  Policy or our practices, please contact us at:
                </h6>
              </li>
              <ul>
                <li className="email-item">
                  <h6>- **Email:** rijuvalikatechnologiespvtltd@gmail.com</h6>
                </li>
                <li>
                  <h6>
                    - **Address:** Amarnath keshri Bhawan , BabuBank, JhaJha,
                    Bihar 811308
                  </h6>
                </li>
              </ul>
            </ul>
          </ol>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
