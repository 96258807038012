import { LeaderboardResponse } from '../store/interface';
import { InstitutionalResponse} from '../store/interface';
import axios from './_dotnetConfiguration';


export const getLeaderboard = async() =>{
  debugger;
  return await axios.get<LeaderboardResponse>('/Leaderboard/GetNationalRankings');
}

export const getInstitutionalboard = async() =>{
  debugger;
  return await axios.get<InstitutionalResponse>('/Leaderboard/GetInstitutionalRankings');
}