import axios from './_nodeConfigurations';
import { Profiles,UpdateProfile } from '../store/interface';
import { promises } from 'dns';

export const getUserProfile = async (): Promise<Profiles> => {
    try {
      const response = await axios.get<Profiles>('/users/user-profile'); // Adjust the endpoint as needed
      return response.data;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
}


export const postUserProfile = async(updateProfile: UpdateProfile):Promise<Profiles> =>{
  try{
    debugger;
    const response =  await axios.post('/users/update-profile',updateProfile);
    return response.data;
  }
  catch(error){
    debugger;
    console.error('Error post user profile:', error);
    throw error;
  }
}